<template>
    <div class="main">
        <div class="staff">
            <img
                class="staff__img"
                :src="staff.image_zoom_out || defaultAvatar"
                alt="фото сотрудника"
            />
            <div class="staff__info">
                <router-link
                    class="staff__link"
                    :to="`/auth/profile/${staff.id}`"
                >
                    <div class="staff__info-title">
                        <div>
                            {{ getName }}
                        </div>
                        <div>
                            {{ getMiddleName }}
                        </div>
                    </div>
                </router-link>
                <div class="staff__info-about">{{ getRole }}</div>
                <div class="staff__info-about">
                    <icon width="26" height="26" class="staff__icon">
                        <path
                            fill="currentColor"
                            d="M22.079,17.835c-1.548-1.324-3.119-2.126-4.648-0.804l-0.913,0.799
                                c-0.668,0.58-1.91,3.29-6.712-2.234C5.005,10.079,7.862,9.22,8.531,8.645l0.918-0.8c1.521-1.325,0.947-2.993-0.15-4.71l-0.662-1.04
                                C7.535,0.382,6.335-0.743,4.81,0.58L3.986,1.3C3.312,1.791,1.428,3.387,0.971,6.419c-0.55,3.638,1.185,7.804,5.16,12.375
                                c3.97,4.573,7.857,6.87,11.539,6.83c3.06-0.033,4.908-1.675,5.486-2.272l0.827-0.721c1.521-1.322,0.576-2.668-0.973-3.995
                                L22.079,17.835z"/></icon
                    ><a
                        v-if="staff.phone_number"
                        class="staff__link"
                        :href="`tel:${staff.phone_number}`"
                        >{{ staff.phone_number || 'Не указан' }}
                    </a>

                    <span v-else>Не указан</span>
                </div>
                <div class="staff__info-about">
                    <icon width="512" height="512" class="staff__icon">
                        <path
                            fill="currentColor"
                            d="M49.106,178.729c6.472,4.567,25.981,18.131,58.528,40.685c32.548,22.554,57.482,39.92,74.803,52.099
                                c1.903,1.335,5.946,4.237,12.131,8.71c6.186,4.476,11.326,8.093,15.416,10.852c4.093,2.758,9.041,5.852,14.849,9.277
                                c5.806,3.422,11.279,5.996,16.418,7.7c5.14,1.718,9.898,2.569,14.275,2.569h0.287h0.288c4.377,0,9.137-0.852,14.277-2.569
                                c5.137-1.704,10.615-4.281,16.416-7.7c5.804-3.429,10.752-6.52,14.845-9.277c4.093-2.759,9.229-6.376,15.417-10.852
                                c6.184-4.477,10.232-7.375,12.135-8.71c17.508-12.179,62.051-43.11,133.615-92.79c13.894-9.703,25.502-21.411,34.827-35.116
                                c9.332-13.699,13.993-28.07,13.993-43.105c0-12.564-4.523-23.319-13.565-32.264c-9.041-8.947-19.749-13.418-32.117-13.418H45.679
                                c-14.655,0-25.933,4.948-33.832,14.844C3.949,79.562,0,91.934,0,106.779c0,11.991,5.236,24.985,15.703,38.974
                                C26.169,159.743,37.307,170.736,49.106,178.729z"
                        />
                        <path
                            fill="currentColor"
                            d="M483.072,209.275c-62.424,42.251-109.824,75.087-142.177,98.501c-10.849,7.991-19.65,14.229-26.409,18.699
                                c-6.759,4.473-15.748,9.041-26.98,13.702c-11.228,4.668-21.692,6.995-31.401,6.995h-0.291h-0.287
                                c-9.707,0-20.177-2.327-31.405-6.995c-11.228-4.661-20.223-9.229-26.98-13.702c-6.755-4.47-15.559-10.708-26.407-18.699
                                c-25.697-18.842-72.995-51.68-141.896-98.501C17.987,202.047,8.375,193.762,0,184.437v226.685c0,12.57,4.471,23.319,13.418,32.265
                                c8.945,8.949,19.701,13.422,32.264,13.422h420.266c12.56,0,23.315-4.473,32.261-13.422c8.949-8.949,13.418-19.694,13.418-32.265
                                V184.437C503.441,193.569,493.927,201.854,483.072,209.275z"
                        /> </icon
                    ><a
                        v-if="staff.email"
                        class="staff__link"
                        :href="`mailto:${staff.email}`"
                        >{{ staff.email }}
                    </a>
                    <span v-else>Не указан</span>
                </div>
            </div>
        </div>
        <hr class="staff__hr" />
    </div>
</template>

<script>
    import Icon from '@/components/icon/Icon'
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg'

    export default {
        components: {
            Icon
        },
        computed: {
            getRole() {
                if (this.staff.positions.name) {
                    return this.staff.positions.name
                }
                const mapRole = {
                    1: 'Администратор',
                    2: 'Сотрудник'
                }
                return mapRole[this.staff.role] || 'Владелец'
            },
            getName() {
                const splitName = this.staff.full_name.split(' ')
                return `${splitName[0]} ${splitName[1]}`
            },
            getMiddleName() {
                const splitName = this.staff.full_name.split(' ')
                if (splitName.length === 3) {
                    return splitName[2]
                }
                return ''
            }
        },
        data() {
            return { defaultAvatar }
        },
        props: {
            staff: {
                type: Object,
                required: true
            }
        }
    }
</script>
<style lang="scss" scoped>
    .main {
        display: flex;
        flex-direction: column;
    }
    .staff {
        display: flex;
        align-items: center;
        padding: 16px 0;
        color: #000;
        &__hr {
            width: 100%;
            margin: 0;
        }
        &__link {
            color: #000;
            &:hover {
                color: #1568ac;
            }
        }
        &__img {
            min-width: 64px;
            width: 64px;
            height: 64px;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 8px;
        }
        &__info {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
            width: 100%;
            gap: 8px;
            &-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }
            &-about {
                display: flex;
                align-items: center;
                font-size: 15px;
            }
        }
        &__icon {
            margin-right: 12px;
            width: 15px;
            min-width: 15px;
            height: 18px;
            color: #e0e3f0;
        }
    }
</style>
