<template>
    <div class="chief">
        <div class="chief__container">
            <div class="chief__head-depart">
                <h2 class="chief__head-staff-title">
                    Начальник отдела
                </h2>
                <div class="chief__head-staff-block">
                    <img
                        class="chief__block-img"
                        :src="chief.image_zoom_out || defaultAvatar"
                        alt="фото начальника отдела"
                    />
                    <div class="chief__block-info">
                        <router-link
                            class="chief__link"
                            :to="`/auth/profile/${chief.id}`"
                        >
                            <div class="chief__block-name">
                                <div>
                                    {{ getName }}
                                </div>
                                <div>
                                    {{ getMiddleName }}
                                </div>
                            </div>
                        </router-link>
                        <div class="chief__block-additional">
                            <div class="chief__phones">
                                <icon
                                    width="26"
                                    height="26"
                                    class="chief__phone-icon"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M22.079,17.835c-1.548-1.324-3.119-2.126-4.648-0.804l-0.913,0.799
                                c-0.668,0.58-1.91,3.29-6.712-2.234C5.005,10.079,7.862,9.22,8.531,8.645l0.918-0.8c1.521-1.325,0.947-2.993-0.15-4.71l-0.662-1.04
                                C7.535,0.382,6.335-0.743,4.81,0.58L3.986,1.3C3.312,1.791,1.428,3.387,0.971,6.419c-0.55,3.638,1.185,7.804,5.16,12.375
                                c3.97,4.573,7.857,6.87,11.539,6.83c3.06-0.033,4.908-1.675,5.486-2.272l0.827-0.721c1.521-1.322,0.576-2.668-0.973-3.995
                                L22.079,17.835z"
                                /></icon>
                                <div class="chief__phones-block">
                                    <div
                                        v-if="
                                            chief.phones && chief.phones.length
                                        "
                                    >
                                        <a
                                            class="chief__phone chief__link"
                                            :href="`tel:${phone.number}`"
                                            v-for="(phone,
                                            index) in chief.phones"
                                            :key="phone.number + index"
                                        >
                                            {{ phone.number
                                            }}<span class="chief__phone-text">{{
                                                getPhoneType(phone.type)
                                            }}</span>
                                        </a>
                                    </div>
                                    <span class="chief__phone" v-else
                                        >Не указан</span
                                    >
                                </div>
                            </div>
                            <div class="chief__email">
                                <icon
                                    width="512"
                                    height="512"
                                    class="chief__email-icon"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M49.106,178.729c6.472,4.567,25.981,18.131,58.528,40.685c32.548,22.554,57.482,39.92,74.803,52.099
                                c1.903,1.335,5.946,4.237,12.131,8.71c6.186,4.476,11.326,8.093,15.416,10.852c4.093,2.758,9.041,5.852,14.849,9.277
                                c5.806,3.422,11.279,5.996,16.418,7.7c5.14,1.718,9.898,2.569,14.275,2.569h0.287h0.288c4.377,0,9.137-0.852,14.277-2.569
                                c5.137-1.704,10.615-4.281,16.416-7.7c5.804-3.429,10.752-6.52,14.845-9.277c4.093-2.759,9.229-6.376,15.417-10.852
                                c6.184-4.477,10.232-7.375,12.135-8.71c17.508-12.179,62.051-43.11,133.615-92.79c13.894-9.703,25.502-21.411,34.827-35.116
                                c9.332-13.699,13.993-28.07,13.993-43.105c0-12.564-4.523-23.319-13.565-32.264c-9.041-8.947-19.749-13.418-32.117-13.418H45.679
                                c-14.655,0-25.933,4.948-33.832,14.844C3.949,79.562,0,91.934,0,106.779c0,11.991,5.236,24.985,15.703,38.974
                                C26.169,159.743,37.307,170.736,49.106,178.729z"
                                    />
                                    <path
                                        fill="currentColor"
                                        d="M483.072,209.275c-62.424,42.251-109.824,75.087-142.177,98.501c-10.849,7.991-19.65,14.229-26.409,18.699
                                c-6.759,4.473-15.748,9.041-26.98,13.702c-11.228,4.668-21.692,6.995-31.401,6.995h-0.291h-0.287
                                c-9.707,0-20.177-2.327-31.405-6.995c-11.228-4.661-20.223-9.229-26.98-13.702c-6.755-4.47-15.559-10.708-26.407-18.699
                                c-25.697-18.842-72.995-51.68-141.896-98.501C17.987,202.047,8.375,193.762,0,184.437v226.685c0,12.57,4.471,23.319,13.418,32.265
                                c8.945,8.949,19.701,13.422,32.264,13.422h420.266c12.56,0,23.315-4.473,32.261-13.422c8.949-8.949,13.418-19.694,13.418-32.265
                                V184.437C503.441,193.569,493.927,201.854,483.072,209.275z"
                                    />
                                </icon>
                                <div>
                                    <a
                                        v-if="chief.email"
                                        class="chief__link"
                                        :href="`mailto:${chief.email}`"
                                        >{{ chief.email }}
                                    </a>
                                    <span v-else>Не указан</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="chief__hr" />
        </div>
    </div>
</template>

<script>
    import Icon from '@/components/icon/Icon'
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg'

    export default {
        components: {
            Icon
        },
        computed: {
            getName() {
                const splitName = this.chief.full_name.split(' ')
                return `${splitName[0]} ${splitName[1]}`
            },
            getMiddleName() {
                const splitName = this.chief.full_name.split(' ')
                if (splitName.length === 3) {
                    return splitName[2]
                }
                return ''
            }
        },
        data() {
            return { defaultAvatar }
        },
        methods: {
            getPhoneType(type) {
                const types = {
                    work: 'Рабочий',
                    internal: 'Внутренний',
                    mobile: 'Мобильный'
                }
                return types[type]
            }
        },
        props: {
            chief: {
                type: Object,
                required: true
            }
        }
    }
</script>
<style lang="scss" scoped>
    $black: #000;
    $gray: #909caf;

    .chief {
        &__hr {
            width: 100%;
            margin: 0;
        }
        &__link {
            color: #000;
            &:hover {
                color: #1568ac;
            }
        }
        &__container {
            margin-bottom: 36px;
        }
        &__head {
            &-staff {
                &-title {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 30px;
                }
                &-block {
                    display: flex;
                    margin-bottom: 24px;
                    flex-wrap: wrap;
                }
            }
        }
        &__email {
            display: flex;
            align-items: center;
            &-icon {
                margin-right: 8px;
                width: 15px;
                min-width: 15px;
                height: 18px;
                color: #e0e3f0;
            }
        }
        &__phone {
            display: flex;
            color: $black;
            font-size: 16px;

            &-text {
                display: flex;
                align-self: center;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: $gray;
                margin-left: 10px;
            }
            &-icon {
                width: 15px;
                min-width: 15px;
                height: 24px;
                color: #e0e3f0;
                align-self: flex-start;
            }
        }
        &__block {
            &-img {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 40px;
                background: #fff;
                width: 170px;
                max-width: 170px;
                height: 170px;
                padding: 8px;
                border-radius: 50%;
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.13);
                @media screen and (max-width: 500px) {
                    width: 130px;
                    height: 130px;
                    max-height: 130px;
                }
            }
            &-info {
                display: flex;
                flex-direction: column;
                margin-top: 15px;
            }
            &-name {
                font-weight: 600;
                font-size: 22px;
                line-height: 30px;
                margin-bottom: 24px;
            }
            &-additional {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -15px -24px 0;
                align-items: flex-start;
                & > * {
                    margin: 0 15px 24px 0;
                }
            }
        }
        &__phones {
            display: flex;
            align-items: center;
            &-block {
                display: flex;
                flex-direction: column;
                margin-right: 40px;
                margin-left: 8px;
                & > .chief__phone + .chief__phone {
                    margin-top: 8px;
                }
            }
        }
    }
</style>
