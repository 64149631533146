<template>
    <content-view>
        <div class="department">
            <div class="department__control">
                <div>
                    <router-link
                        to="/structure"
                        tag="a"
                        class="department__btn department__btn--back"
                    >
                        <icon
                            height="10"
                            width="6"
                            class="department__btn--back-icon department__btn-icon"
                        >
                            <svg
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.5 0.5L1 5L5.5 9.5"
                                    stroke="currentColor"
                                />
                            </svg>
                        </icon>
                        <span class="department__btn--back-title">
                            Назад
                        </span>
                    </router-link>
                </div>
                <div class="department__control-group">
                    <router-link
                        :to="`/department/${departmentId}/edit`"
                        class="department__btn department__btn--edit"
                    >
                        <icon
                            class="department__btn-icon"
                            height="12"
                            width="12"
                            icon-color="currentColor"
                        >
                            <path
                                d="M0 9.49953V11.9999H2.50035L9.87802 4.62217L7.37767 2.12183L0 9.49953Z"
                            />
                            <path
                                d="M11.5985 1.7519L10.0416 0.195027C9.78154 -0.065009 9.35813 -0.065009 9.0981 0.195027L7.87793 1.4152L10.3783 3.91554L11.5984 2.69537C11.8585 2.43534 11.8585 2.01193 11.5985 1.7519Z"
                            />
                        </icon>
                    </router-link>

                    <button v-if="department.parent" @click="onClickDelete" type="button" class="department__btn department__btn--delete">
                        <icon
                            class="department__btn-icon"
                            height="16"
                            width="16"
                            icon-color="#989CAE"
                        >
                            <g clip-path="url(#clip0)">
                                <path
                                    d="M13.2908 1.60494H10.4329V0.52175C10.4329 0.233625 10.1993 0 9.91111 0H6.08946C5.80133 0 5.56771 0.233594 5.56771 0.52175V1.60494H2.70974C2.42161 1.60494 2.18799 1.83853 2.18799 2.12669V3.73159C2.18799 4.01972 2.42158 4.25334 2.70974 4.25334H13.2908C13.5789 4.25334 13.8125 4.01975 13.8125 3.73159V2.12669C13.8126 1.8385 13.579 1.60494 13.2908 1.60494ZM9.38933 1.60494H6.61121V1.04347H9.38933V1.60494Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M2.91211 5.29688L3.34473 15.5005C3.35655 15.7798 3.58645 16.0001 3.86598 16.0001H12.1337C12.4133 16.0001 12.6432 15.7798 12.655 15.5005L13.0876 5.29688H2.91211ZM6.42267 13.8784C6.42267 14.1665 6.18908 14.4001 5.90092 14.4001C5.6128 14.4001 5.37917 14.1665 5.37917 13.8784V7.41856C5.37917 7.13044 5.61277 6.89681 5.90092 6.89681C6.18905 6.89681 6.42267 7.13041 6.42267 7.41856V13.8784ZM8.52152 13.8784C8.52152 14.1665 8.28792 14.4001 7.99977 14.4001C7.71161 14.4001 7.47802 14.1665 7.47802 13.8784V7.41856C7.47802 7.13044 7.71161 6.89681 7.99977 6.89681C8.28792 6.89681 8.52152 7.13041 8.52152 7.41856V13.8784ZM10.6204 13.8784C10.6204 14.1665 10.3868 14.4001 10.0986 14.4001C9.81045 14.4001 9.57686 14.1665 9.57686 13.8784V7.41856C9.57686 7.13044 9.81045 6.89681 10.0986 6.89681C10.3868 6.89681 10.6204 7.13041 10.6204 7.41856V13.8784Z"
                                    fill="currentColor"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </icon>
                    </button>
                </div>
            </div>
            <template v-if="!isLoading">
                <div class="department__text">
                    <new-editor
                        class="department__text-editor"
                        :data="description"
                        :editable="false"
                        top-placeholder="Название отдела"
                        bottom-placeholder="Описание отдела"
                    />
                </div>
                <Chief
                    v-if="department.chiefs && department.chiefs.length"
                    :chief="department.chiefs[0]"
                />
                <div
                    v-if="department.staff && department.staff.length"
                    class="department__staffs"
                >
                    <h2 class="department__staffs-title">
                        {{ getStaffTitle }}
                    </h2>
                    <Staff
                        v-for="staff in department.staff"
                        :key="staff.id"
                        :staff="staff"
                    />
                </div>
            </template>
            <loading-spinner v-else></loading-spinner>
        </div>
    </content-view>
</template>

<script>
    import session from '@/api/session'
    import ContentView from '@/views/menu/ContentView'
    import Icon from '@/components/icon/Icon'
    import Chief from '@/components/department/Chief'
    import Staff from '@/components/department/Staff'
    import LoadingSpinner from '@/components/LoadingSpinner'
    import { metaTitle } from '@/mixins/meta_title'
    import NewEditor from '@/components/editor/newEditor'

    export default {
        mixins: [metaTitle],
        components: {
            NewEditor,
            ContentView,
            LoadingSpinner,
            Staff,
            Chief,
            Icon
        },
        computed: {
            setTitle() {
                return 'Отдел ' + this.department.name
            },
            getStaffTitle() {
                return this.department.parent
                    ? 'Сотрудники отдела'
                    : 'Руководители'
            }
        },
        data() {
            return {
                isLoading: true,
                departmentId: this.$route.params.department_id,
                department: {},
                description: ''
            }
        },
        async created() {
            this.isLoading = true
            await session
                .get(`/api/v1/structure/${this.departmentId}/`)
                .then(response =>
                    response.data.parent
                        ? (this.department = response.data)
                        : (this.department = {
                              ...response.data,
                              chiefs: [],
                              staff: response.data.chiefs
                          })
                )
                .catch(error => console.error(error))
            this.description =
                `<h1>${this.department.name}</h1>` +
                (this.department.description || '<p>Описание отсутствует</p>')
            this.isLoading = false
        },

        methods: {

            onClickDelete() {

                this.$swal({
                    customClass: {
                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                    },
                    title: 'Удалить отдел?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Да, удалить!',
                    cancelButtonText: 'Отменить'
                }).then(async value => {
                    if (value.value) {
                        let swal = this.$swal({
                            title: 'Удаление...',
                            icon: 'info',
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                        })
                        try {
                            const request = await session.delete(
                                `/api/v1/structure/${this.departmentId}/`
                            )
                            swal.close()
                            this.$swal({
                                title: 'Отлично!',
                                text: 'Отдел удален',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400
                            })

                            this.$router.push('/structure')
                        } catch (err) {
                            console.log(err)
                            swal.close()
                            this.$swal({
                                title: 'Ошибка!',
                                text:
                                    'При обработке запроса произошла ошибка на сервере',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1400
                            })
                        }
                    }
                })
            }

        }
    }
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';

    .department {
        max-width: 960px;
        margin: 0 auto;
        padding: 0 20px;
        &__staffs {
            &-title {
                margin-bottom: 14px;
            }
        }
        &__text {
            &-editor {
                margin: 0;
            }
        }
        &__control {
            display: flex;
            justify-content: space-between;
            margin-bottom: 32px;
            &-group {
                display: flex;
            }
        }
        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            border: 2px solid #e6ebf3;
            border-radius: 50%;
            color: #989cae;
            background-color: transparent;
            &--back {
                border: none;
                border-radius: none;
                height: auto;
                width: auto;
                background: none;
                &-icon {
                    margin-right: 10px;
                }
            }
            &:hover {
                cursor: pointer;
                border-color: $gold;
                & > .department__btn-icon,
                .department__btn--back-title {
                    color: black;
                }
            }
            &:nth-child(n + 2) {
                margin-left: 10px;
            }
        }
    }
</style>
